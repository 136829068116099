import { _isSent } from "@/app/providers/slices/AppSlice";
import { faContactCard, faLocation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tab, Tabs, useDisclosure } from "@heroui/react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import ContactInfoModal from "../modals/product/ContactInfoModal";
import TabContentItem from "./TabContentItem";
import { FaWhatsapp } from 'react-icons/fa';
import getWhatsAppUrl from '@/app/Utils/getWhatsAppUrl';

export interface ICustomTabProps {
  product: any;
}

export default function CustomTab(props: ICustomTabProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isSent = useSelector(_isSent);

  useEffect(() => {
    !isSent ?
      onOpen()
      :
      onClose()
  }, [isSent, onClose, onOpen]);

  const handleContactUs = () => {
    try {
      const phoneNumber = props.product?.seller?.phone_number; // Adjust this field if needed
      if (!phoneNumber) {
        console.error('Phone number is not available.');
        return;
      }

      const currentURL = typeof window !== 'undefined' ? window.location.href : '';
      const prefilledMessage = `Salut, j'aimerais avoir plus de détails sur cette annonce que j'ai trouvée sur CasaKonnect: 
  - Nom: ${props.product?.name}
  - Numéro d'identification: ${props.product?.listingNumber}
  Lien: ${currentURL}`;

      const whatsAppUrl = getWhatsAppUrl(phoneNumber, prefilledMessage);

      // Open WhatsApp in a new tab
      window.open(whatsAppUrl, '_blank');
    } catch (error) {
      console.error('Error opening WhatsApp chat:', error);
    }
  };

  return (
    <div className="flex w-full flex-col">
      <Tabs aria-label="Options" color='default' variant='light'>
        <Tab
          key="description"
          title={
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faContactCard} />
              <span className=" text-small">Description</span>
            </div>
          }
        >
          <div className=" flex flex-col px-2 text-small">
            <span>
              {props.product?.description}
            </span>
          </div>
        </Tab>
        <Tab
          key="contact"
          title={
            <div className="flex items-center space-x-2 p-2 rounded-lg">
              <FontAwesomeIcon icon={faContactCard} />
              <span className=" text-small ">Reserver</span>
            </div>
          }
        >
          <ContactInfoModal product={props.product} isOpen={isOpen} onClose={() => onClose()} />
          {
            isSent ?
              <div className=" flex flex-col px-2 text-small">
                <TabContentItem title="Contact" value={props.product?.seller?.username} />
                <div onClick={handleContactUs} className="bg-green-500 text-white w-[150px] p-2 rounded-lg cursor-pointer" style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                  <FaWhatsapp size="20" /> {props.product?.seller?.phone_number}
                </div>
                <TabContentItem title="Email" value={props.product?.seller?.email} />
              </div> :
              <div className="flex flex-col">
                <span className=" text-small font-extralight mb-2"> Entrez vos infos</span>

                <Button size='sm' color='default' onClick={onOpen}>
                  Reserver
                </Button>
              </div>

          }

        </Tab>
        <Tab
          key="address"
          title={
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faLocation} />
              <span className=" text-small">Addresse</span>
            </div>
          }
        >
          <div className=" flex flex-col px-2">
            <TabContentItem title="Ville" value={props.product?.city?.name} />
            {/* <TabContentItem title="Address" value="113 stella Road plumsted" /> */}
          </div>

        </Tab>

      </Tabs>
    </div>
  );
}
