'use client';
import { useState, TouchEvent, useCallback } from "react";
import { _selectedImage, setSelectedImage } from "@/app/providers/slices/AppSlice";
import { Button, Modal, ModalBody, ModalContent, ModalFooter, Snippet } from "@heroui/react";
import Image from 'next/image';
import { useDispatch, useSelector } from "react-redux";
import CustomTab from "../../tabs/CustomTab";
import { X, ZoomIn, ZoomOut, ChevronLeft, ChevronRight } from 'lucide-react';
import { FaWhatsapp } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

export interface IProductDetailProps {
    isOpen: boolean;
    onClose: () => void;
    product?: any;
}


interface Position {
    x: number;
    y: number;
}

interface ImageLightboxProps {
    imageUrl: string;
    altText: string;
    isOpen: boolean;
    onClose: () => void;
    onNext: () => void;
    onPrev: () => void;
    hasNext: boolean;
    hasPrev: boolean;
}

// ImageLightbox Component
const ImageLightbox = ({
    imageUrl,
    altText,
    isOpen,
    onClose,
    onNext,
    onPrev,
    hasNext,
    hasPrev
}: ImageLightboxProps) => {
    const [scale, setScale] = useState<number>(1);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const [position, setPosition] = useState<Position>({ x: 0, y: 0 });
    const [dragStart, setDragStart] = useState<Position>({ x: 0, y: 0 });
    const [touchStart, setTouchStart] = useState<number | null>(null);

    // Reset position and scale when image changes
    const resetView = useCallback(() => {
        setScale(1);
        setPosition({ x: 0, y: 0 });
    }, []);

    if (!isOpen || !imageUrl) return null;

    const handleZoomIn = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        setScale(prev => Math.min(prev + 0.5, 4));
    };

    const handleZoomOut = (e?: React.MouseEvent) => {
        e?.stopPropagation();
        setScale(prev => {
            const newScale = Math.max(prev - 0.5, 1);
            if (newScale === 1) {
                // Reset position when fully zoomed out
                setPosition({ x: 0, y: 0 });
            }
            return newScale;
        });
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        if (scale === 1) return; // Only allow dragging when zoomed in
        e.stopPropagation();
        setIsDragging(true);
        setDragStart({
            x: e.clientX - position.x,
            y: e.clientY - position.y
        });
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging || scale === 1) return;

        const newPosition = {
            x: e.clientX - dragStart.x,
            y: e.clientY - dragStart.y
        };

        // Add bounds to prevent dragging too far
        const maxX = (scale - 1) * 250; // Half of image width
        const maxY = (scale - 1) * 250; // Half of image height

        newPosition.x = Math.max(Math.min(newPosition.x, maxX), -maxX);
        newPosition.y = Math.max(Math.min(newPosition.y, maxY), -maxY);

        setPosition(newPosition);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleWheel = (e: React.WheelEvent) => {
        e.preventDefault();
        if (e.deltaY < 0) {
            handleZoomIn();
        } else {
            handleZoomOut();
        }
    };

    const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
        if (scale > 1) return; // Don't handle swipes when zoomed
        setTouchStart(e.touches[0].clientX);
    };

    const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
        if (scale > 1 || touchStart === null) return;

        const currentTouch = e.touches[0].clientX;
        const diff = touchStart - currentTouch;

        // Increased threshold for more reliable swipe detection
        if (Math.abs(diff) > 75) { // Increased from 50 to 75
            if (diff > 0 && hasNext) {
                onNext();
                resetView();
                setTouchStart(null);
            } else if (diff < 0 && hasPrev) {
                onPrev();
                resetView();
                setTouchStart(null);
            }
        }
    };

    const handleTouchEnd = () => {
        setTouchStart(null);
    };

    return (
        <div
            className="fixed inset-0 bg-black bg-opacity-90 z-[60] flex items-center justify-center"
            onClick={onClose}
        >
            <div className="relative w-full h-full flex items-center justify-center">
                {/* Close button */}
                <button
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                        resetView();
                    }}
                    className="absolute top-4 right-4 text-white hover:text-gray-300 p-2 z-70"
                >
                    <X size={24} />
                </button>

                {/* Zoom controls */}
                <div className="absolute top-4 left-4 flex gap-2 z-70">
                    <button
                        onClick={handleZoomIn}
                        className="bg-white/10 p-2 rounded-full hover:bg-white/20"
                    >
                        <ZoomIn size={24} className="text-white" />
                    </button>
                    <button
                        onClick={handleZoomOut}
                        className="bg-white/10 p-2 rounded-full hover:bg-white/20"
                    >
                        <ZoomOut size={24} className="text-white" />
                    </button>
                    <span className="text-white/70 flex items-center ml-2">
                        {(scale * 100).toFixed(0)}%
                    </span>
                </div>

                {/* Navigation buttons - Hidden on mobile */}
                <div className="hidden md:block">
                    {hasPrev && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onPrev();
                                resetView();
                            }}
                            className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/10 p-2 rounded-full hover:bg-white/20 z-70"
                        >
                            <ChevronLeft size={24} className="text-white" />
                        </button>
                    )}
                    {hasNext && (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onNext();
                                resetView();
                            }}
                            className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/10 p-2 rounded-full hover:bg-white/20 z-70"
                        >
                            <ChevronRight size={24} className="text-white" />
                        </button>
                    )}
                </div>

                {/* Mobile swipe indicator */}
                {scale === 1 && (
                    <div className="absolute bottom-4 left-0 right-0 md:hidden">
                        <div className="text-white/50 text-center text-sm">
                            Swipe to navigate
                        </div>
                    </div>
                )}

                {/* Image container */}
                <div
                    className={`relative overflow-hidden ${scale > 1 ? 'cursor-move' : 'cursor-zoom-in'}`}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (scale === 1) handleZoomIn();
                    }}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onWheel={handleWheel}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <Image
                        src={imageUrl}
                        alt={altText}
                        width={500}
                        height={500}
                        className="max-h-[90vh] w-auto object-contain select-none"
                        style={{
                            transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
                            transition: isDragging ? 'none' : 'transform 0.2s',
                        }}
                        draggable={false}
                        priority
                    />
                </div>
            </div>
        </div>
    );
};

// Main ProductDetail Component
export default function ProductDetail(props: IProductDetailProps) {
    const selectedImage = useSelector(_selectedImage);
    const dispatch = useDispatch();
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);

    const imageItems = props.product?.images?.map((item: any) => (
        <Image
            key={item.id}
            onClick={() => dispatch(setSelectedImage(item))}
            width={100}
            height={100}
            className={`h-20 mb-1 border rounded-xl object-fill ${selectedImage?.id === item.id && 'border-black border-medium'}  w-full`}
            src={item.url}
            alt={`Thumbnail of ${props.product?.name}`}
        />
    ));

    const handleNextImage = () => {
        const currentIndex = props.product?.images.findIndex((img: any) => img.id === selectedImage?.id);
        if (currentIndex < props.product?.images.length - 1) {
            dispatch(setSelectedImage(props.product?.images[currentIndex + 1]));
        }
    };

    const handlePrevImage = () => {
        const currentIndex = props.product?.images.findIndex((img: any) => img.id === selectedImage?.id);
        if (currentIndex > 0) {
            dispatch(setSelectedImage(props.product?.images[currentIndex - 1]));
        }
    };

    const currentImageIndex = props.product?.images.findIndex((img: any) => img.id === selectedImage?.id);
    const valueToCopy = process.env.NEXT_PUBLIC_FRONT_URL + 'products-listing/' + props.product?.slug;

    const handleContact = () => {
        const phone = (props?.product?.seller.phone_number);
        const phoneNumber = phone.trim().replace(/\u200E|\u200F|\u202C/g, '');
        const message = `Bonjour CasaKonnect! Je suis intéressé par ${props.product?.name} avec comme No. de reference ${props.product?.listingNumber}. ${valueToCopy}`;
        const whatsappLink = `https://wa.me/${phoneNumber.trim()}?text=${encodeURIComponent(message)}`;

        // Trigger tracking event (if gtag is available)
        if (typeof window !== 'undefined' && window.gtag) {
            window.gtag('event', 'click', {
                event_category: 'whatsApp',
                event_label: 'WhatsApp Button Click',
                value: 1,
            });
        }

        // Redirect to WhatsApp link
        window.open(whatsappLink, '_blank');
    };
    return (
        <>
            <Modal
                size={'4xl'}
                isOpen={props.isOpen}
                onClose={props.onClose}
                scrollBehavior='inside'
                placement="center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalBody>
                                <div className="grid md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 grid-cols-1 mt-8">
                                    <div className="col-span-3">
                                        <Image
                                            width={500}
                                            height={500}
                                            className='mb-1 rounded-xl h-80 object-fill w-full cursor-zoom-in'
                                            src={selectedImage?.url}
                                            alt={`Selected image of ${props.product?.name}`}
                                            onClick={() => setIsLightboxOpen(true)}
                                        />
                                        <div className='grid md:grid-cols-5 lg:grid-cols-5 xl:grid-cols-5 grid-cols-3 mt-4 gap-3'>
                                            {imageItems}
                                        </div>
                                    </div>
                                    <div className="col-span-2 flex flex-col p-3">
                                        <span className="text-small text-gray-400 font-extralight">{props.product?.subcategory?.name}</span>
                                        <span className="text-3xl font-bold">{props.product?.name}</span>
                                        <div className="flex mt-3">
                                            <span className="font-bold text-xl text-red-500">$ {new Intl.NumberFormat().format(props.product?.price)}</span>
                                        </div>

                                        <div className="flex flex-col mt-3">
                                            <span className="text-small text-gray-400 font-extralight">Reference: {props.product?.listingNumber}</span>
                                            <span className="text-small text-gray-500 font-extralight">{props.product?.provider?.name}</span>
                                            <span className="text-small text-gray-400 font-extralight mt-2"><FontAwesomeIcon icon={faLocationDot} /> {props.product?.city.name}</span>
                                            <div className="flex flex-col mt-3">
                                                <Button onClick={handleContact} className="w-full sm:w-auto bg-black hover:bg-green-700 text-white">  <FaWhatsapp size={25} />Acheter Maintenant</Button>
                                            </div>
                                            <Snippet
                                                onCopy={() => navigator.clipboard.writeText(valueToCopy)}
                                                size='sm'
                                                color="default"
                                                className="mt-2"
                                            >
                                                Copier et partager le lien
                                            </Snippet>
                                        </div>
                                        <div className="border-t-small mt-6 py-4">
                                            <div className="mt-2">
                                                <CustomTab product={props.product} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color='default' onPress={onClose}>
                                    Fermer
                                </Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>

            {/* Lightbox */}
            <ImageLightbox
                imageUrl={selectedImage?.url}
                altText={`Selected image of ${props.product?.name}`}
                isOpen={isLightboxOpen}
                onClose={() => setIsLightboxOpen(false)}
                onNext={handleNextImage}
                onPrev={handlePrevImage}
                hasNext={currentImageIndex < props.product?.images.length - 1}
                hasPrev={currentImageIndex > 0}
            />
        </>
    );
}